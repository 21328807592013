import dayjs from 'dayjs'

/**
 * return 今年度
 */
export const nendo = (year?: number, month?: number): number => {
    const now = new Date()
    return dayjs(new Date(year ?? now.getFullYear(), month ?? now.getMonth()))
        .subtract(3, 'months')
        .year()
}

export const getTime = () => {
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth() + 1
    return { year, month }
}

export const generateMonths = () => Array.from({ length: 12 }, (_, i) => i + 1).map((m) => ({ id: m, value: `${m}月` }))
export const generateYears = () => {
    const { year } = getTime()
    return Array.from({ length: year - 2015 }, (_, i) => i + 2016)
        .map((y) => ({ id: y, value: `${y}年` }))
        .reverse()
}
