// import { authApi } from '@/ghgApi'
import { authApi } from '@/ghgApi'
import { TAllFormValues } from '@/react-hook-form/types'
import { routes } from '@/routes'
import caches from '@/utils/cache'
import { Auth } from '@aws-amplify/auth'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { navigate } from 'gatsby'

interface AuthSliceTypes {
    loading: boolean
    mode: string | null
    role: string | null
    token?: string | null
    logedIn: boolean
    error?: string | string[]
    userName?: string
}

const adminLogin = async (user: TAllFormValues['Login']) => {
    const adminAcc = await authApi.loginAdmin(user)
    return { ...adminAcc, userName: user.email }
}

export const checkAuthenticator = createAsyncThunk('auth/checkAuthen', async () => {
    return await Auth.currentAuthenticatedUser()
})

export const userLogin = createAsyncThunk('auth/login', async (formUser: TAllFormValues['Login']) => {
    return adminLogin(formUser)
})

export const userLogout = createAsyncThunk('auth/logout', async () => {
    return await Auth.signOut({ global: true })
})

export const slice = createSlice({
    name: 'auth',
    initialState: {
        loading: false,
        mode: null,
        role: null,
        token: 'example token',
        logedIn: false,
        userName: undefined,
    } as AuthSliceTypes,
    reducers: {},
    extraReducers: (build) => {
        // login
        build.addCase(userLogin.pending, (state, { payload }) => {
            state.loading = true
        })
        build.addCase(userLogin.fulfilled, (state, { payload }: any) => {
            state.loading = false
            state.error = ''
            if (payload) {
                caches.set('admin_access_token', payload.data.access_token)
                caches.set('admin_refresh_token', payload.data.refresh_token)
                caches.set('admin_info_user', payload.userName)
                state.logedIn = true
            }
            navigate(routes.home)
        })
        build.addCase(userLogin.rejected, (state, actions) => {
            state.loading = false
            state.logedIn = false
            state.error = 'メールアドレスまたはパスワードが違います。'
        })
        // check user is authenticator
        build.addCase(checkAuthenticator.pending, (state, { payload }) => {
            state.loading = true
        })
        build.addCase(checkAuthenticator.fulfilled, (state, { payload }) => {
            state.loading = false
            if (caches.get('admin_access_token')) state.logedIn = true
            else navigate(routes.login)
            // FIXME: to be fixed when BE done api refresh token
            // caches.set('admin_access_token', payload.access_token)
            // caches.set('admin_refresh_token', payload.refresh_token)
        })
        build.addCase(checkAuthenticator.rejected, (state, { payload }) => {
            navigate(routes.login)
            state.loading = false
            state.logedIn = false
        })
        // logout
        build.addCase(userLogout.pending, (state, { payload }) => {
            state.loading = true
        })
        build.addCase(userLogout.fulfilled, (state, { payload }) => {
            state.loading = false
            state.logedIn = false
            caches.remove('admin_access_token')
            caches.remove('admin_refresh_token')
            caches.remove('admin_info_user')
            navigate(routes.login)
        })
        build.addCase(userLogout.rejected, (state, { payload }) => {
            state.loading = false
        })
    },
})

// Action creators are generated for each case reducer function
export const {} = slice.actions

// Get value from state
export const authStore = (state: { auth: AuthSliceTypes }) => state.auth

export default slice.reducer
