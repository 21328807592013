import { notificationApi } from '@/ghgApi'
import { Notification, NotificationCreate } from '@/openapi'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

interface NotificationSliceTypes {
    loading: boolean
    error?: string | string[]
    listNotification: Notification[]
    total: number
    reUpdate: boolean
    selectedNoti: Notification | null
}

interface TOptions {
    search?: string
    year?: number
    offset?: number
    limit?: number
}

export const getListNotification = createAsyncThunk(
    'notification/getList',
    async (options: TOptions, { rejectWithValue }) => {
        try {
            const response = await notificationApi.listNotifications(
                options?.search,
                options?.year,
                options?.offset,
                options?.limit,
            )
            return response
        } catch (err) {
            rejectWithValue(err)
        }
    },
)

export const createNotification = createAsyncThunk(
    'notification/create',
    async (data: NotificationCreate, { rejectWithValue }) => {
        try {
            const response = await notificationApi.createNotification(data)
            return response
        } catch (err) {
            return rejectWithValue(err)
        }
    },
)

export const updateNotification = createAsyncThunk(
    'notification/update',
    async (data: { id: number; dataUpdate: NotificationCreate }, { rejectWithValue }) => {
        try {
            const response = await notificationApi.updateNotification(data.id, data.dataUpdate)
            return response
        } catch (err) {
            return rejectWithValue(err)
        }
    },
)

export const deleteNotification = createAsyncThunk('notification/delete', async (id: number, { rejectWithValue }) => {
    try {
        const response = await notificationApi.deleteNotification(id)
        return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const slice = createSlice({
    name: 'notification',
    initialState: {
        loading: false,
        error: '',
        listNotification: [],
        total: 0,
        reUpdate: false,
        selectedNoti: null,
    } as NotificationSliceTypes,
    reducers: {
        setSelectedNoti(state, { payload }: { payload: Notification | null }) {
            state.selectedNoti = payload
        },
    },
    extraReducers: (build) => {
        // list notification
        build.addCase(getListNotification.pending, (state, { payload }) => {
            state.loading = true
        })
        build.addCase(getListNotification.fulfilled, (state, { payload }) => {
            state.loading = false
            state.error = ''
            if (payload?.data.data) state.listNotification = payload.data.data
            if (payload?.data.total || payload?.data.total === 0) state.total = payload?.data.total
        })
        build.addCase(getListNotification.rejected, (state, actions) => {
            state.loading = false
            state.error = 'An error occurred,!'
        })
        // create notification
        build.addCase(createNotification.pending, (state, { payload }) => {
            state.loading = true
        })
        build.addCase(createNotification.fulfilled, (state, { payload }) => {
            state.loading = false
            state.error = ''
            state.reUpdate = !state.reUpdate
        })
        build.addCase(createNotification.rejected, (state, actions) => {
            state.loading = false
            state.error = 'An error occurred,!'
        })
        // update notification
        build.addCase(updateNotification.pending, (state, { payload }) => {
            state.loading = true
        })
        build.addCase(updateNotification.fulfilled, (state, { payload }) => {
            state.loading = false
            state.error = ''
            const index = state.listNotification.findIndex((noti) => noti.id === payload.data.id)
            state.listNotification[index] = { ...payload.data }
        })
        build.addCase(updateNotification.rejected, (state, actions) => {
            state.loading = false
            state.error = 'An error occurred,!'
        })
        // delete notification
        build.addCase(deleteNotification.pending, (state, { payload }) => {
            state.loading = true
        })
        build.addCase(deleteNotification.fulfilled, (state, { payload }) => {
            state.loading = false
            state.error = ''
            state.reUpdate = !state.reUpdate
        })
        build.addCase(deleteNotification.rejected, (state, actions) => {
            state.loading = false
            state.error = 'An error occurred,!'
        })
    },
})

// Action creators are generated for each case reducer function
export const { setSelectedNoti } = slice.actions

// Get value from state
export const notificationStore = (state: { notificationStore: NotificationSliceTypes }) => state.notificationStore

export default slice.reducer
