import { organizationApi, siteApi, userApi } from '@/ghgApi'
import { Organization, OrganizationUpdate, Site, SiteUpdate, User, UserCreate, UserUpdate } from '@/openapi'
import { TAllFormValues, TSite } from '@/react-hook-form/types'
import translate from '@/utils/translations'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
interface TOptions {
    search?: string
    planId?: number
    status?: number
    offset?: number
    limit?: number
}
interface CompanySliceTypes {
    loading: boolean
    error: string | null
    listCompanies: Organization[]
    listSites: Site[]
    listUsers: User[]
    total: number
    reUpdate: boolean
    reUpdateModal: boolean
    organizationId?: number | null
    sitesTotal: number
    usersTotal: number
}

export const createCompany = createAsyncThunk(
    'company/createCompany',
    async (data: TAllFormValues['CreateCompany'], { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await organizationApi.createOrganization(data)
            return fulfillWithValue(response)
        } catch (err) {
            return rejectWithValue(err)
        }
    },
)

export const getListCompanies = createAsyncThunk('company/getListCompanies', async (options?: TOptions) => {
    const getCompanies = await organizationApi.listOrganization(
        options?.search,
        options?.planId,
        options?.status,
        options?.offset,
        options?.limit,
    )
    return getCompanies
})

export const getSitesByOrganizationId = createAsyncThunk(
    'company/getListSite',
    async (
        data: { id: number; options?: { search?: string; offset?: number; limit?: number } },
        { fulfillWithValue, rejectWithValue },
    ) => {
        try {
            const response = await organizationApi.getOrganizationSites(
                data.id,
                data.options?.search,
                data.options?.offset,
                data.options?.limit,
            )
            return { data: response, organizationId: data.id }
        } catch (err) {
            return rejectWithValue(err)
        }
    },
)
export const createSite = createAsyncThunk(
    'company/createSite',
    async (data: { site: Pick<TSite, 'name'>; organizationId: number }, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await organizationApi.createOrganizationSite(data.organizationId, data.site)
            return response
        } catch (err) {
            return rejectWithValue(err)
        }
    },
)
export const deleteSite = createAsyncThunk(
    'company/deleteSite',
    async (id: number, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await siteApi.deleteSite(id)
            return response
        } catch (err) {
            return rejectWithValue(err)
        }
    },
)
export const updateSite = createAsyncThunk(
    'company/updateSite',
    async ({ id, siteUpdate }: { id: number; siteUpdate?: SiteUpdate }, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await siteApi.updateSite(id, siteUpdate)
            return response
        } catch (err) {
            return rejectWithValue(err)
        }
    },
)

export const getUsersByOrganizationId = createAsyncThunk(
    'company/getListUsers',
    async (
        data: { id: number; options?: { search?: string; offset?: number; limit?: number; role?: number } },
        { fulfillWithValue, rejectWithValue },
    ) => {
        try {
            const response = await organizationApi.getOrganizationUsers(
                data.id,
                data.options?.search,
                data.options?.role,
                data.options?.offset,
                data.options?.limit,
            )
            return { data: response, organizationId: data.id }
        } catch (err) {
            return rejectWithValue(err)
        }
    },
)
export const createUser = createAsyncThunk(
    'company/createUser',
    async (data: { id: number; user: UserCreate }, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await organizationApi.createOrganizationUser(data.id, data.user)
            return response
        } catch (err) {
            const a = await rejectWithValue(err)
            return a
        }
    },
)
export const deleteUser = createAsyncThunk(
    'company/deleteUser',
    async (id: string, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await userApi.deleteUser(id)
            return response
        } catch (err) {
            return rejectWithValue(err)
        }
    },
)
export const updateUser = createAsyncThunk(
    'company/updateUser',
    async ({ id, userUpdate }: { id: string; userUpdate: UserUpdate }, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await userApi.updateUser(id, userUpdate)
            return response
        } catch (err) {
            return rejectWithValue(err)
        }
    },
)

export const updateCompany = createAsyncThunk(
    'company/edit',
    async (
        { id, companyUpdate }: { id: number; companyUpdate: OrganizationUpdate },
        { fulfillWithValue, rejectWithValue },
    ) => {
        try {
            const response = await organizationApi.updateOrganization(id, companyUpdate)
            return response
        } catch (err) {
            return rejectWithValue(err)
        }
    },
)

export const slice = createSlice({
    name: 'company',
    initialState: {
        loading: false,
        error: null,
        listCompanies: [],
        listSites: [],
        listUsers: [],
        total: 0,
        reUpdate: false,
        reUpdateModal: false,
        organizationId: null,
        sitesTotal: 0,
        usersTotal: 0,
    } as CompanySliceTypes,
    reducers: {
        setCompanyError(state, { payload }: { payload: string }) {
            state.error = payload
        },
        setOrganizationId(state, { payload }: { payload: number }) {
            state.organizationId = payload
        },
    },
    extraReducers: (build) => {
        // get list companies
        build.addCase(getListCompanies.pending, (state, { payload }) => {
            state.loading = true
        })
        build.addCase(getListCompanies.fulfilled, (state, { payload: { data } }) => {
            state.loading = false
            state.error = ''
            if (data.data) {
                state.listCompanies = data.data
            }
            if (data.total || data.total === 0) state.total = data.total
        })
        build.addCase(getListCompanies.rejected, (state, actions) => {
            state.loading = false
        })
        // create company
        build.addCase(createCompany.pending, (state, { payload }) => {
            state.loading = true
        })
        build.addCase(createCompany.fulfilled, (state, { payload }) => {
            state.loading = false
            state.error = ''
            state.reUpdate = !state.reUpdate
        })
        build.addCase(createCompany.rejected, (state, { payload }) => {
            state.loading = false
            const error = payload as AxiosError
            if (error.response?.data?.errors) state.error = translate(error.response?.data?.errors[0].message) as string
        })
        // get list sites
        build.addCase(getSitesByOrganizationId.pending, (state, { payload }) => {
            state.loading = true
        })
        build.addCase(
            getSitesByOrganizationId.fulfilled,
            (
                state,
                {
                    payload: {
                        data: { data },
                        organizationId,
                    },
                },
            ) => {
                state.loading = false
                state.error = ''
                state.organizationId = organizationId
                if (data.data) {
                    state.listSites = data.data
                }
                if (data.total) state.sitesTotal = data.total
            },
        )
        build.addCase(getSitesByOrganizationId.rejected, (state, actions) => {
            state.loading = false
        }),
            // create site
            build.addCase(createSite.pending, (state, { payload }) => {
                state.loading = true
            })
        build.addCase(createSite.fulfilled, (state, { payload: { data } }) => {
            state.loading = false
            state.error = ''
            if (data) {
                state.reUpdateModal = !state.reUpdateModal
            }
        })
        build.addCase(createSite.rejected, (state, actions) => {
            state.loading = false
        })
        // delete site
        build.addCase(deleteSite.pending, (state, { payload }) => {
            state.loading = true
        })
        build.addCase(deleteSite.fulfilled, (state, { payload }) => {
            state.loading = false
            state.error = ''
            state.reUpdateModal = !state.reUpdateModal
        })
        build.addCase(deleteSite.rejected, (state, actions) => {
            // TODO: to be fix in feature/error-message
            // console.log('err', actions.payload)
            state.loading = false
        })
        // update site
        build.addCase(updateSite.pending, (state, { payload }) => {
            state.loading = true
        })
        build.addCase(updateSite.fulfilled, (state, { payload }) => {
            state.loading = false
            state.error = ''
            state.reUpdateModal = !state.reUpdateModal
        })
        build.addCase(updateSite.rejected, (state, actions) => {
            // console.log('err', actions.payload)
            state.loading = false
        })
        // get list users
        build.addCase(getUsersByOrganizationId.pending, (state, { payload }) => {
            state.loading = true
        })
        build.addCase(
            getUsersByOrganizationId.fulfilled,
            (
                state,
                {
                    payload: {
                        data: { data },
                        organizationId,
                    },
                },
            ) => {
                state.loading = false
                state.error = ''
                state.organizationId = organizationId
                if (data.data) {
                    state.listUsers = data.data
                }
                if (data.total) state.usersTotal = data.total
            },
        )
        build.addCase(getUsersByOrganizationId.rejected, (state, actions) => {
            state.loading = false
        })
        // create user
        build.addCase(createUser.pending, (state, { payload }) => {
            state.loading = true
        })
        build.addCase(createUser.fulfilled, (state, { payload: { data } }) => {
            state.loading = false
            state.error = ''
            if (data) {
                state.reUpdateModal = !state.reUpdateModal
                console.log(data)
            }
        })
        build.addCase(createUser.rejected, (state, { payload }) => {
            state.loading = false
        })
        // delete user
        build.addCase(deleteUser.pending, (state, { payload }) => {
            state.loading = true
        })
        build.addCase(deleteUser.fulfilled, (state, { payload }) => {
            state.loading = false
            state.error = ''
            state.reUpdateModal = !state.reUpdateModal
        })
        build.addCase(deleteUser.rejected, (state, actions) => {
            // TODO: to be fix in feature/error-message
            state.loading = false
        })
        // update user
        build.addCase(updateUser.pending, (state, { payload }) => {
            state.loading = true
        })
        build.addCase(updateUser.fulfilled, (state, { payload }) => {
            state.loading = false
            state.error = ''
            state.reUpdateModal = !state.reUpdateModal
        })
        build.addCase(updateUser.rejected, (state, actions) => {
            // TODO: to be fix in feature/error-message
            state.loading = false
        })
        // update company
        build.addCase(updateCompany.pending, (state, { payload }) => {
            state.loading = true
        })
        build.addCase(updateCompany.fulfilled, (state, { payload }) => {
            state.loading = false
            state.error = ''
            state.reUpdate = !state.reUpdate
        })
        build.addCase(updateCompany.rejected, (state, actions) => {
            // TODO: to be fix in feature/error-message
            state.loading = false
        })
    },
})

// Action creators are generated for each case reducer function
export const { setCompanyError, setOrganizationId } = slice.actions

// Get value from state
export const companyStore = (state: { companyStore: CompanySliceTypes }) => state.companyStore

export default slice.reducer
