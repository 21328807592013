import { configureStore } from '@reduxjs/toolkit'
import auth from './slices/authSlice'
import system from './slices/systemSlice'
import companyStore from './slices/companiesSlice'
import notificationStore from './slices/notificationSlice'
import emissionStore from './slices/emissionSlice'
import messageStore from './slices/messageSlice'
// ...
const store = configureStore({
    reducer: {
        auth,
        system,
        companyStore,
        notificationStore,
        emissionStore,
        messageStore,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
