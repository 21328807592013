import { emissionFactorApi, organizationApi } from '@/ghgApi'
import {
    EmissionFactor,
    EmissionFactorCreate,
    EmissionFactorTable,
    EmissionFactorUpdate,
    Organization,
} from '@/openapi'
import { TAllFormValues } from '@/react-hook-form/types'
import { getTime } from '@/utils/nendo'
import translate from '@/utils/translations'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

export interface IOptions {
    search?: string
    offset?: number
    limit?: number
    emissionFactorTableId?: number
    year?: number
    type?: string
}
interface EmissionSliceTypes {
    loading: boolean
    error: string | null
    listEmissionFactorTable: Array<EmissionFactorTable>
    listEmissionFactorTableId: Array<EmissionFactor>
    total: number
    totalFactorTableId: number
    reUpdate: boolean
    reUpdateEmissionId: boolean
    emissionFactor: TAllFormValues['addEditEmissionId']
}

const { year } = getTime()

const defaultEmissionFactorValues: TAllFormValues['addEditEmissionId'] = {
    id: 0,
    name: '',
    unit: '',
    value: '',
    emission_factor_table_id: 1,
    year: year,
    anti_warming: 'antiWarming',
    energy_saving: 'energySaving',
    anti_warming_id: 1,
    energy_saving_id: 1,
}

export const createEmissionFactorTable = createAsyncThunk(
    'emission/createEmissionFactorTable',
    async (data: TAllFormValues['addEditEmission'], { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await emissionFactorApi.createEmissionFactorTable(data)
            return fulfillWithValue(response)
        } catch (err) {
            return rejectWithValue(err)
        }
    },
)

export const getEmissionFactorTables = createAsyncThunk(
    'emission/getEmissionFactorTables',
    async (options?: IOptions) => {
        const getEmissionFactors = await emissionFactorApi.getEmissionFactorTables(
            options?.search,
            options?.offset,
            options?.limit,
        )
        return getEmissionFactors
    },
)

export const createEmissionFactor = createAsyncThunk(
    'emission/createEmissionFactor',
    async (value: EmissionFactorCreate, { rejectWithValue }) => {
        try {
            const getEmissionFactors = await emissionFactorApi.createEmissionFactor(value)
            return getEmissionFactors
        } catch (err) {
            rejectWithValue(err)
        }
    },
)
export const editEmissionFactor = createAsyncThunk(
    'emission/editEmissionFactor',
    async (value: EmissionFactorUpdate & { id: number }, { fulfillWithValue, rejectWithValue }) => {
        try {
            const getEmissionFactors = await emissionFactorApi.updateEmissionFactor(value.id, value)
            return fulfillWithValue(getEmissionFactors)
        } catch (err) {
            return rejectWithValue(err)
        }
    },
)

export const getEmissionFactorTableID = createAsyncThunk(
    'emission/getEmissionFactorTable',
    async (options?: IOptions) => {
        const getEmissionFactorID = await emissionFactorApi.getEmissionFactors(
            options?.search,
            options?.emissionFactorTableId,
            options?.year,
            options?.offset,
            options?.limit,
            options?.type,
        )
        return getEmissionFactorID
    },
)
export const slice: any = createSlice({
    name: 'emission',
    initialState: {
        loading: false,
        error: null,
        listEmissionFactorTable: [],
        total: 0,
        totalFactorTableId: 0,
        reUpdate: false,
        listEmissionFactorTableId: [],
        reUpdateEmissionId: false,
        emissionFactor: defaultEmissionFactorValues,
    } as EmissionSliceTypes,
    reducers: {
        setCompanyError(state, { payload }: { payload: string }) {
            state.error = payload
        },
        setEmissionFactor(state, { payload }: { payload: TAllFormValues['addEditEmissionId'] }) {
            state.emissionFactor = payload
        },
        resetEmissionFactor(state, action) {
            state.emissionFactor = defaultEmissionFactorValues
        },
    },
    extraReducers: (build) => {
        build.addCase(getEmissionFactorTables.pending, (state, { payload }) => {
            state.loading = true
        })
        build.addCase(getEmissionFactorTables.fulfilled, (state, { payload: { data } }) => {
            state.loading = false
            state.error = ''
            if (data.data) {
                state.listEmissionFactorTable = data.data
            }
            if (data.total || data.total === 0) state.total = data.total
        })
        build.addCase(getEmissionFactorTables.rejected, (state, actions) => {
            state.loading = false
        })

        build.addCase(createEmissionFactorTable.pending, (state, { payload }) => {
            state.loading = true
        })
        build.addCase(createEmissionFactorTable.fulfilled, (state, { payload }) => {
            state.loading = false
            state.error = ''
            state.reUpdate = !state.reUpdate
        })
        build.addCase(createEmissionFactorTable.rejected, (state, { payload }) => {
            state.loading = false
            const error = payload as AxiosError
            if (error.response?.data?.errors) {
                state.error = translate(error.response?.data?.errors[0].message) as string
            }
        })

        build.addCase(getEmissionFactorTableID.pending, (state, { payload }) => {
            state.loading = true
        })
        build.addCase(getEmissionFactorTableID.fulfilled, (state, { payload: { data } }) => {
            state.loading = false
            state.error = ''
            if (data) {
                state.listEmissionFactorTableId = data.data || []
            }
            if (data.total || data.total === 0) state.totalFactorTableId = data.total
        })
        build.addCase(getEmissionFactorTableID.rejected, (state, actions) => {
            state.loading = false
        })

        build.addCase(createEmissionFactor.fulfilled, (state, { payload }) => {
            state.loading = false
            state.error = ''
            state.reUpdate = !state.reUpdate
        })
        build.addCase(createEmissionFactor.rejected, (state, { payload }) => {
            state.loading = false
        })

        build.addCase(editEmissionFactor.fulfilled, (state, { payload }) => {
            state.loading = false
            state.error = ''
            state.reUpdateEmissionId = !state.reUpdateEmissionId
        })
        build.addCase(editEmissionFactor.rejected, (state, { payload }) => {
            state.loading = false
        })
    },
})

export const { setCompanyError, setEmissionFactor, resetEmissionFactor } = slice.actions

export const emissionStore = (state: { emissionStore: EmissionSliceTypes }) => state.emissionStore

export default slice.reducer
