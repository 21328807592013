import { createSlice } from '@reduxjs/toolkit'

interface SystemSliceTypes {
    expandedMenu: boolean
}

export const slice = createSlice({
    name: 'system',
    initialState: {
        expandedMenu: true,
    } as SystemSliceTypes,
    reducers: {
        setExpandedMenu(state, { payload }: { payload: { expand: boolean } }) {
            state.expandedMenu = payload.expand
        },
    },
})

// Action creators are generated for each case reducer function
export const { setExpandedMenu } = slice.actions

// Get value from state
export const systemStore = (state: { system: SystemSliceTypes }) => state.system

export default slice.reducer
