import { messageStore } from '@/store/slices/messageSlice'
import theme from '@/theme'
import { Alert, Slide, Snackbar, Stack } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import React, { ReactNode, useEffect } from 'react'
import { useSelector } from 'react-redux'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />
})

function Messages({ children }: { children: ReactNode | ReactNode[] }) {
    const { message, type, reUpdate } = useSelector(messageStore)
    const [open, setOpen] = React.useState(false)

    useEffect(() => {
        if (message) setOpen(true)
    }, [message, reUpdate])

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        setOpen(false)
    }

    return (
        <div>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar
                    open={open}
                    autoHideDuration={5000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    TransitionComponent={Transition}
                >
                    <Alert
                        onClose={handleClose}
                        severity={type}
                        sx={{ width: '100%', fontFamily: theme.typography.fontFamily }}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            </Stack>
            {children}
        </div>
    )
}
export default Messages
