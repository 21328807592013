import { createSlice } from '@reduxjs/toolkit'

type TType = 'success' | 'error' | 'warning'
type TMessage = string | string[] | null
interface MessageSliceTypes {
    message?: TMessage
    type: TType
    reUpdate: boolean
}

export const slice = createSlice({
    name: 'message',
    initialState: {
        message: null,
        type: 'success',
        reUpdate: false,
    } as MessageSliceTypes,
    reducers: {
        setMessage(state, { payload }: { payload: { message: TMessage; type: TType } }) {
            state.message = payload.message
            state.type = payload.type
            state.reUpdate = !state.reUpdate
        },
    },
    extraReducers: (build) => {},
})

// Action creators are generated for each case reducer function
export const { setMessage } = slice.actions

// Get value from state
export const messageStore = (state: { messageStore: MessageSliceTypes }) => state.messageStore

export default slice.reducer
