export const routes = {
    home: '/',
    login: '/accounts/login',
    forgotPassword: '/accounts/forgot-password',
    resetPassword: '/accounts/change-password',
    emissionFactor: '/emission-factor',
    notification: '/notification',
    emissionFactorId: '/emission-factor-id',
    PRIVACY_POLICY: '/info/privacy-policy',
    PRIVACY_POLICY_FULL: '/info/privacy-policy-full',
    NOT_FOUND: '/404',
    addEditEmissionFactor: '/emission-factor-id/form',
}
