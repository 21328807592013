/**
 * Amplifyからのエラーメッセージを和訳するためのdict.
 */
export const translations: { [key: string]: { [key: string]: string } } = {
    ja: {
        'User does not exist.': 'メールアドレスまたはパスワードが違います。',
        'Incorrect username or password.': 'メールアドレスまたはパスワードが違います。',
        'Password attempts exceeded': 'アカウントが一時的にブロックされています',
        'Invalid verification code provided, please try again.': '不正な認証コードです。もう1度お試しください。',

        'Organization name existed': '企業名称が存在しました',
        'Emission Factor Table is existed': 'メールアドレスまたはパスワードが違います。',
        'Attempt limit exceeded, please try after some time.':
            '試行回数の制限を超えました。しばらくしてからもう一度お試しください。',
        'Username/client id combination not found.': 'メールアドレスは登録されていません。',
    },
}

export default function translate(message: string) {
    const email = message.split(' ')[1]
    if (message.includes(`Email ${email} existed`)) return `${email} が存在しました。`

    const translation = translations['ja'][message]

    if (translation) {
        return translation
    } else {
        console.warn('エラーメッセージの日本語訳はまだありません。')
    }
}
