import Color from '@/enums/Color'
import { createTheme, ThemeOptions } from '@mui/material'
// import createTheme, { ThemeOptions } from '@material-ui/core/styles/createTheme'

/**
 * https://material-ui.com/guides/typescript/#customization-of-theme
 */
declare module '@mui/material' {
    interface Theme {
        default: {
            margin: number
        }
        header: {
            height: number
        }
        menu: {
            fontSize: number
            width: number
        }
        contentContainer: {
            width: number
            padding: number
            paddingLeft: number
        }
        contentArea?: {
            width: number
        }
        colors: { [key: string]: string }
    }

    interface ThemeOptions {
        default?: {
            margin: number
        }
        header?: {
            height?: number
        }
        menu?: {
            fontSize?: number
            width?: number
        }
        footer?: {
            footerHeight: string
            footerShrinkHeight: string
        }
        contentContainer?: {
            width: number
            padding: number
            paddingLeft: number
        }
        contentArea?: {
            width: number
        }
        colors?: { [key: string]: string }
    }
}

export function createMyTheme(options: ThemeOptions) {
    return createTheme({
        default: {
            margin: 30,
        },
        typography: {
            fontFamily: ' "ヒラギノ角ゴ ProN","Hiragino Kaku Gothic ProN","メイリオ","Meiryo",sans-serif',
            fontSize: 12,
            fontWeightBold: 600,
        },
        header: {
            height: 60,
        },
        menu: {
            fontSize: 14,
            width: 200,
        },
        footer: {
            footerHeight: '125px',
            footerShrinkHeight: '81px',
        },
        contentContainer: {
            width: 1600,
            padding: 30,
            paddingLeft: 60,
        },
        contentArea: {
            width: 980,
        },
        palette: {
            primary: {
                main: '#5b8aad',
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: 8,
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        color: '#222222',
                    },
                },
            },
        },
        colors: Color,
        ...options,
    })
}

const theme = createMyTheme({})
export default theme
