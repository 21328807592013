// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-accounts-forgot-password-tsx": () => import("./../../../src/pages/accounts/forgot-password.tsx" /* webpackChunkName: "component---src-pages-accounts-forgot-password-tsx" */),
  "component---src-pages-accounts-login-tsx": () => import("./../../../src/pages/accounts/login.tsx" /* webpackChunkName: "component---src-pages-accounts-login-tsx" */),
  "component---src-pages-emission-factor-id-form-tsx": () => import("./../../../src/pages/emission-factor-id/form.tsx" /* webpackChunkName: "component---src-pages-emission-factor-id-form-tsx" */),
  "component---src-pages-emission-factor-id-index-tsx": () => import("./../../../src/pages/emission-factor-id/index.tsx" /* webpackChunkName: "component---src-pages-emission-factor-id-index-tsx" */),
  "component---src-pages-emission-factor-index-tsx": () => import("./../../../src/pages/emission-factor/index.tsx" /* webpackChunkName: "component---src-pages-emission-factor-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-privacy-policy-tsx": () => import("./../../../src/pages/info/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-info-privacy-policy-tsx" */),
  "component---src-pages-notification-index-tsx": () => import("./../../../src/pages/notification/index.tsx" /* webpackChunkName: "component---src-pages-notification-index-tsx" */)
}

